// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-rs-js": () => import("./../../../src/pages/about/index.rs.js" /* webpackChunkName: "component---src-pages-about-index-rs-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-rs-js": () => import("./../../../src/pages/contact/index.rs.js" /* webpackChunkName: "component---src-pages-contact-index-rs-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-rs-js": () => import("./../../../src/pages/index.rs.js" /* webpackChunkName: "component---src-pages-index-rs-js" */),
  "component---src-pages-services-index-en-js": () => import("./../../../src/pages/services/index.en.js" /* webpackChunkName: "component---src-pages-services-index-en-js" */),
  "component---src-pages-services-index-rs-js": () => import("./../../../src/pages/services/index.rs.js" /* webpackChunkName: "component---src-pages-services-index-rs-js" */),
  "component---src-pages-works-index-en-js": () => import("./../../../src/pages/works/index.en.js" /* webpackChunkName: "component---src-pages-works-index-en-js" */),
  "component---src-pages-works-index-rs-js": () => import("./../../../src/pages/works/index.rs.js" /* webpackChunkName: "component---src-pages-works-index-rs-js" */)
}

